import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { SenhaNovaComponent } from './senha-nova/senha-nova.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'nova-senha', component: SenhaNovaComponent },
  { path: 'seguranca', loadChildren: () => import('../security/security.module').then(m => m.SecurityModule) },
  { path: 'cadastro', loadChildren: () => import('../cadastros/cadastros.module').then(m => m.CadastrosModule) },
  { path: 'pgr-gro', loadChildren: () => import('../pgr-gro/pgr-gro.module').then(m => m.PgrGroModule) },
  { path: 'orientacoes', loadChildren: () => import('../orientacoes/orientacoes.module').then(m => m.OrientacoesModule) },
  { path: 'dashboard', loadChildren: () => import('../dashboard/dashboard.module').then(m => m.DashboardModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
