import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule, registerLocaleData } from "@angular/common";
import localePt from "@angular/common/locales/pt";

//Definindo cultura PT ao devextreme
import { locale, loadMessages } from "devextreme/localization";

//Carregando mensagens traduzidas
let messagesPt = require("devextreme/localization/messages/pt.json");
registerLocaleData(localePt);
loadMessages(messagesPt);
locale("pt");

// Configurando FontAwesome
import {
  FontAwesomeModule,
  FaIconLibrary
} from "@fortawesome/angular-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

import { FlexLayoutModule } from "@angular/flex-layout";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRootModule } from './app-root/app-root.module';

@NgModule({
  declarations: [AppComponent, ],
  imports: [
    BrowserAnimationsModule,

    // Angular
    CommonModule,
    BrowserModule,
    HttpClientModule,
    FontAwesomeModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,

    AppRoutingModule,
    AppRootModule
  ],
  providers: [{ provide: LOCALE_ID, useValue: "pt" }],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far, fab);
  }
}
