import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DxActionSheetComponent } from 'devextreme-angular';
import { Util } from '../../util';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit {
  @ViewChild('acaoExportar', { static: false }) acaoExportar!: DxActionSheetComponent;
  @Input() titulo: string = "";
  @Input() faImagem: any;
  @Input() imagem: any;
  @Input() carregar: boolean = false;
  @Input() component: string = "";
  @Input() tiposExportacao: any;
  @Input() exibirAtualizar: boolean = false;
  @Input() exibirNovo: boolean = false;
  @Input() exibirFiltro: boolean = false;
  @Input() exibirImportar: boolean = false;

  @Output() exportar = new EventEmitter<string>();
  @Output() novo = new EventEmitter<boolean>();
  @Output() atualizar = new EventEmitter<boolean>();
  @Output() filtrar = new EventEmitter<string>();
  @Output() importer = new EventEmitter<boolean>();

  @Input() acoes: any;
  acoesExportar: any;
  findIcon: any;

  constructor(public util: Util) {
    this.acoes = [];
    this.acoesExportar = [];
    this.findIcon = {
      icon: "find",
      type: "normal",
      stylingMode: "text",
      hoverStateEnabled: false,
      disabled: true
    }
  }

  ngOnInit() {
    if (this.exibirNovo)
      this.acoes.push({ text: "Novo ", icon: "file", type: "success", stylingMode: "outlined", onClick: () => { this.eventoNovo() } });
    if (this.exibirAtualizar)
      this.acoes.push({ text: "Atualizar", icon: "refresh", type: "default", stylingMode: "outlined", onClick: () => { this.eventoAtualizar() } });
    if (this.tiposExportacao) {
      this.acoes.push({ text: "Exportar ", icon: "export", onClick: () => { this.acaoExportar.instance.show() } });
      this.tiposExportacao.forEach((f: any) => {
        this.acoesExportar.push({ text: f, onClick: () => { this.eventoExportar({ itemData: f }) } });
      })
    }
  }

  eventoExportar(e: any) {
    this.exportar.emit(e.itemData);
  }

  eventoAtualizar() {
    this.atualizar.emit(true);
  }

  eventoNovo() {
    this.novo.emit(true);
  }

  eventoFiltrar(e: any) {
    this.filtrar.emit(e.text);
  }

  eventoImportar() {
    this.importer.emit()
  }

}
