import { Component, OnInit, Injector } from '@angular/core';
import { BaseComponent } from 'src/app/common/base.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent extends BaseComponent implements OnInit {

  constructor(injector: Injector, private router: Router) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(["/login"]);
    }
  }

}
