import { Component, OnInit, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { EmpresaModel } from "src/app/cadastros/models/empresa.model";
import { EmpresaService } from "src/app/cadastros/services/empresa.service";
import { BaseComponent } from "src/app/common/base.component";
import { UsuarioModel } from "src/app/common/models/usuario.model";
import { GlobalService } from "src/app/common/services/global.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent extends BaseComponent implements OnInit {
  returnUrl: string = "";
  usuario?: UsuarioModel;
  loadingLogin: boolean = false;
  txtLogin: string = "";
  senha: string = "";
  btnText: string = "";
  dadosEmpresas: EmpresaModel[] = [];
  idEmpresaSelecionada!: number;
  showSelecionarEmpresa: boolean = false;
  passwordMode: string = "";
  passwordButton: any;
  passwordButtonView: any;
  userIcon: any;
  passwordIcon: any;
  versao: string = this.globalService.versaoSistema;
  
  
  constructor(injector: Injector, private router: Router, public global: GlobalService, public empresaService: EmpresaService) {
    super(injector);
    this.passwordMode = 'password';
    this.passwordButton = {
      icon: "data: image/svg+xml; base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGFyaWEtaGlkZGVuPSJ0cnVlIiBmb2N1c2FibGU9ImZhbHNlIiBkYXRhLXByZWZpeD0iZmFzIiBkYXRhLWljb249ImV5ZSIgcm9sZT0iaW1nIiB3aWR0aD0iNTc2IiBoZWlnaHQ9IjUxMiIgc3R5bGU9IiI+PHJlY3QgaWQ9ImJhY2tncm91bmRyZWN0IiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB4PSIwIiB5PSIwIiBmaWxsPSJub25lIiBzdHJva2U9Im5vbmUiLz48ZyBjbGFzcz0iY3VycmVudExheWVyIiBzdHlsZT0iIj48dGl0bGU+TGF5ZXIgMTwvdGl0bGU+PHBhdGggZmlsbD0iIzk5OTk5OSIgZD0iTTU3Mi41MiAyNDEuNEM1MTguMjkgMTM1LjU5IDQxMC45MyA2NCAyODggNjRTNTcuNjggMTM1LjY0IDMuNDggMjQxLjQxYTMyLjM1IDMyLjM1IDAgMCAwIDAgMjkuMTlDNTcuNzEgMzc2LjQxIDE2NS4wNyA0NDggMjg4IDQ0OHMyMzAuMzItNzEuNjQgMjg0LjUyLTE3Ny40MWEzMi4zNSAzMi4zNSAwIDAgMCAwLTI5LjE5ek0yODggNDAwYTE0NCAxNDQgMCAxIDEgMTQ0LTE0NCAxNDMuOTMgMTQzLjkzIDAgMCAxLTE0NCAxNDR6bTAtMjQwYTk1LjMxIDk1LjMxIDAgMCAwLTI1LjMxIDMuNzkgNDcuODUgNDcuODUgMCAwIDEtNjYuOSA2Ni45QTk1Ljc4IDk1Ljc4IDAgMSAwIDI4OCAxNjB6IiBpZD0ic3ZnXzEiIGNsYXNzPSJzZWxlY3RlZCIgZmlsbC1vcGFjaXR5PSIxIi8+PC9nPjwvc3ZnPg==",
      type: "normal",
      stylingMode: "text",
      hoverStateEnabled: false,
      onClick: () => {
        this.passwordMode = this.passwordMode === "text" ? "password" : "text";
      }
    }
    this.passwordButtonView = {
      icon: "data: image/svg+xml; base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGFyaWEtaGlkZGVuPSJ0cnVlIiBmb2N1c2FibGU9ImZhbHNlIiBkYXRhLXByZWZpeD0iZmFzIiBkYXRhLWljb249ImV5ZS1zbGFzaCIgcm9sZT0iaW1nIiB3aWR0aD0iNjQwIiBoZWlnaHQ9IjUxMiIgc3R5bGU9IiI+PHJlY3QgaWQ9ImJhY2tncm91bmRyZWN0IiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB4PSIwIiB5PSIwIiBmaWxsPSJub25lIiBzdHJva2U9Im5vbmUiLz48ZyBjbGFzcz0iY3VycmVudExheWVyIiBzdHlsZT0iIj48dGl0bGU+TGF5ZXIgMTwvdGl0bGU+PHBhdGggZmlsbD0iIzk5OTk5OSIgZD0iTTMyMCA0MDBjLTc1Ljg1IDAtMTM3LjI1LTU4LjcxLTE0Mi45LTEzMy4xMUw3Mi4yIDE4NS44MmMtMTMuNzkgMTcuMy0yNi40OCAzNS41OS0zNi43MiA1NS41OWEzMi4zNSAzMi4zNSAwIDAgMCAwIDI5LjE5Qzg5LjcxIDM3Ni40MSAxOTcuMDcgNDQ4IDMyMCA0NDhjMjYuOTEgMCA1Mi44Ny00IDc3Ljg5LTEwLjQ2TDM0NiAzOTcuMzlhMTQ0LjEzIDE0NC4xMyAwIDAgMS0yNiAyLjYxem0zMTMuODIgNTguMWwtMTEwLjU1LTg1LjQ0YTMzMS4yNSAzMzEuMjUgMCAwIDAgODEuMjUtMTAyLjA3IDMyLjM1IDMyLjM1IDAgMCAwIDAtMjkuMTlDNTUwLjI5IDEzNS41OSA0NDIuOTMgNjQgMzIwIDY0YTMwOC4xNSAzMDguMTUgMCAwIDAtMTQ3LjMyIDM3LjdMNDUuNDYgMy4zN0ExNiAxNiAwIDAgMCAyMyA2LjE4TDMuMzcgMzEuNDVBMTYgMTYgMCAwIDAgNi4xOCA1My45bDU4OC4zNiA0NTQuNzNhMTYgMTYgMCAwIDAgMjIuNDYtMi44MWwxOS42NC0yNS4yN2ExNiAxNiAwIDAgMC0yLjgyLTIyLjQ1em0tMTgzLjcyLTE0MmwtMzkuMy0zMC4zOEE5NC43NSA5NC43NSAwIDAgMCA0MTYgMjU2YTk0Ljc2IDk0Ljc2IDAgMCAwLTEyMS4zMS05Mi4yMUE0Ny42NSA0Ny42NSAwIDAgMSAzMDQgMTkyYTQ2LjY0IDQ2LjY0IDAgMCAxLTEuNTQgMTBsLTczLjYxLTU2Ljg5QTE0Mi4zMSAxNDIuMzEgMCAwIDEgMzIwIDExMmExNDMuOTIgMTQzLjkyIDAgMCAxIDE0NCAxNDRjMCAyMS42My01LjI5IDQxLjc5LTEzLjkgNjAuMTF6IiBpZD0ic3ZnXzEiIGNsYXNzPSJzZWxlY3RlZCIgZmlsbC1vcGFjaXR5PSIxIi8+PC9nPjwvc3ZnPg==",
      type: "normal",
      stylingMode: "text",
      hoverStateEnabled: false,
      onClick: () => {
        this.passwordMode = this.passwordMode === "text" ? "password" : "text";
      }
    }

    this.userIcon = {
      icon: "data: image/svg+xml; base64, PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJ1c2VyIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtdXNlciBmYS13LTE0IiByb2xlPSJpbWciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDQ0OCA1MTIiPjxwYXRoIGZpbGw9ImN1cnJlbnRDb2xvciIgZD0iTTIyNCAyNTZjNzAuNyAwIDEyOC01Ny4zIDEyOC0xMjhTMjk0LjcgMCAyMjQgMCA5NiA1Ny4zIDk2IDEyOHM1Ny4zIDEyOCAxMjggMTI4em04OS42IDMyaC0xNi43Yy0yMi4yIDEwLjItNDYuOSAxNi03Mi45IDE2cy01MC42LTUuOC03Mi45LTE2aC0xNi43QzYwLjIgMjg4IDAgMzQ4LjIgMCA0MjIuNFY0NjRjMCAyNi41IDIxLjUgNDggNDggNDhoMzUyYzI2LjUgMCA0OC0yMS41IDQ4LTQ4di00MS42YzAtNzQuMi02MC4yLTEzNC40LTEzNC40LTEzNC40eiI+PC9wYXRoPjwvc3ZnPg==",
      type: "normal",
      stylingMode: "text",
      hoverStateEnabled: false,
      disabled: true
    }
    this.passwordIcon = {
      icon: "data: image/svg+xml; base64, PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJsb2NrIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtbG9jayBmYS13LTE0IiByb2xlPSJpbWciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDQ0OCA1MTIiPjxwYXRoIGZpbGw9ImN1cnJlbnRDb2xvciIgZD0iTTQwMCAyMjRoLTI0di03MkMzNzYgNjguMiAzMDcuOCAwIDIyNCAwUzcyIDY4LjIgNzIgMTUydjcySDQ4Yy0yNi41IDAtNDggMjEuNS00OCA0OHYxOTJjMCAyNi41IDIxLjUgNDggNDggNDhoMzUyYzI2LjUgMCA0OC0yMS41IDQ4LTQ4VjI3MmMwLTI2LjUtMjEuNS00OC00OC00OHptLTEwNCAwSDE1MnYtNzJjMC0zOS43IDMyLjMtNzIgNzItNzJzNzIgMzIuMyA3MiA3MnY3MnoiPjwvcGF0aD48L3N2Zz4=",
      type: "normal",
      stylingMode: "text",
      hoverStateEnabled: false,
      disabled: true
    }
  }

  ngOnInit() {
    this.btnText = "Autenticar";
    if (this.authService.isLoggedIn()) {
      this.router.navigate([""]);
    }
    this.loading = false;
    this.returnUrl = this.authService.getRedirectUrl();
  }

  onFormSubmit = (e: any) => {
    this.login();
    e.preventDefault();
  }

  login() {
    this.loadingLogin = true;
    this.btnText = "Autenticando...";
    this.authService.authenticateUser(this.txtLogin, this.senha).subscribe((data: any) => {
      this.authService.setAccessToken(data.token);
      this.authService.setTimeValid();
      this.authService.getUser(data.id).subscribe((user: UsuarioModel) => {
        this.usuario = user;
        this.usuario.senha = "";

        this.empresaService.buscarPorUsuario(user.id!).subscribe(empresas => {
          this.dadosEmpresas = empresas.filter(f => f.ativo);
          if (this.dadosEmpresas.length == 1) {
            this.idEmpresaSelecionada = this.dadosEmpresas[0].id!;
            this.authService.setCompany(this.dadosEmpresas[0])
            this.buscarRecursos();

          } else if (this.dadosEmpresas.length > 1) {
            this.showSelecionarEmpresa = false;
            this.showSelecionarEmpresa = true;

          } else {
            this.util.showError("Usuário sem permissão para acessar o sistema.");
            this.globalService.clearMenu();
            this.authService.logout();
            this.btnText = "Autenticar";
            this.loadingLogin = false;
          }
        })


      },
        error => {
          this.authService.logout();
          this.loadingLogin = false;
          this.btnText = "Autenticar";
        }
      );
    },
      error => {
        this.util.showError("Usuário ou senha inválidos.");
        this.loadingLogin = false;
        this.btnText = "Autenticar";
      }
    );
  }

  buscarRecursos() {
    this.authService.getUserResource(this.usuario?.id!, this.idEmpresaSelecionada)
      .subscribe((permissoes: any) => {
        this.authService.setUser(this.usuario!);
        if (permissoes && permissoes.length > 0) {
          this.authService.setUserResource(permissoes);
          if (this.usuario && this.usuario.nova_senha) {
            this.btnText = "Autenticar";
            window.setTimeout(() => {
              this.router.navigate(["/nova-senha"]);
            }, 500);
          } else {
            if (this.returnUrl) {
              this.global.setMenu();
              window.setTimeout(() => {
                this.router.navigate([this.returnUrl]);
              }, 500);
            }
            else {
              this.global.setMenu();
              window.setTimeout(() => {
                this.router.navigate(["/home"]);
              }, 800);
            }
          }
        }
        else {
          this.util.showError("Usuário sem permissão para acessar o sistema.");
          this.globalService.clearMenu();
          window.setTimeout(() => {
            this.router.navigate(["/unauthorized"]);
          }, 800);
          this.btnText = "Autenticar";
          this.showSelecionarEmpresa = false;
        }

        this.loadingLogin = false;
      },
        error => {
          this.btnText = "Autenticar";
          this.util.showError("Falha ao autenticar o usuário, tente novamente.");
          this.loadingLogin = false;
        });
  }

  setEmpresa(e: any) {
    this.idEmpresaSelecionada = e.itemData.id;
    this.authService.setCompany(e.itemData!)
    this.buscarRecursos();
  }

  logout() {
    this.authService.logout();
  }
}
