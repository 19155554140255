<div class="content">
  <div fxFlex="100" fxLayout="row" fxLayoutGap="8px">
    <div fxFlex="*" fxLayout="column"> </div>
    <div fxFlex="35" fxLayout="column wrap" fxLayoutAlign="center center" fxLayoutGap="18px" class="side-menu">
      <span class="title">Acesso negado</span>
      <span class="text">Para acessar a página solicitada, contacte o administrador.</span>
      <dx-button id="button" text="Sair" width="380px" [height]="40" type="success" (onClick)="navigate()"> 
      </dx-button>
    </div>
  </div>
</div>