import { Injectable } from '@angular/core';
import { MenuModel } from '../models/menu.model';
import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' })
export class GlobalService {
  private _menuItems: MenuModel[] = new Array<MenuModel>();


  constructor(private authService: AuthenticationService) {

  }

  get menuItems(): MenuModel[] {
    return this._menuItems;
  }

  get versaoSistema(): string{
    return "v.1 26-01-2023"
  }

  get showMenu(): boolean {
    let user = this.authService.getLoggedUser();
    return (user && this.menuItems.length > 0);
  }

  get showUser(): boolean {
    let user = this.authService.getLoggedUser();
    if (user)
      return true;
    else
      return false;
  }

  public setMenu() {
    this._menuItems = new Array<MenuModel>();

    //CADASTROS
    this._menuItems.push(new MenuModel("Cadastros", "fas", "list-alt", "", "", [
      new MenuModel("Empresas", "fas", "industry", "cadastro/empresa", "CAD_EMPRESA"),
      new MenuModel("Título das Recomendações", "fas", "hand-holding-medical", "cadastro/tipo-recomendacao", "CAD_TIPO_RECOMENDACAO"),
      new MenuModel("Certificados de Calibrações", "fas", "award", "cadastro/certificado-calibracao", "CAD_CERTIFICADO_CALIBRACAO"),
      new MenuModel("Unidade Física", "fab", "unsplash", "cadastro/unidade-fisica", "CAD_UNIDADE_FISICA"),
      new MenuModel("Itens Normativos", "fas", "clipboard-list", "cadastro/item-normativo", "CAD_ITEM_NORMATIVO"),
      new MenuModel("NRE", "fas", "palette", "cadastro/nre", "CAD_NRE"),
      new MenuModel("Mobiliários", "fas", "truck-loading", "cadastro/mobiliario", "CAD_MOBILIARIO"),
      new MenuModel("Partes do Corpo", "fas", "child", "cadastro/parte-corpo", "CAD_PARTE_CORPO"),
      new MenuModel("População Exposta", "fas", "users", "cadastro/populacao-exposta", "CAD_POPULACAO_EXPOSTA"),
      new MenuModel("Fontes de Perigo", "fas", "exclamation", "cadastro/fonte-perigo", "CAD_FONTE_PERIGO"),
      new MenuModel("Status do Fator e Situação", "fas", "exclamation", "cadastro/status-fator-situacao", "CAD_STATUS_FATOR_SITUACAO"),
      new MenuModel("Fatores de Risco", "fas", "skull-crossbones", "cadastro/fator-risco", "CAD_FATOR_RISCO"),
      new MenuModel("Tipos de Risco", "fas", "prescription", "cadastro/tipo-risco", "CAD_TIPO_RISCO"),
      new MenuModel("Situações Analisada", "fas", "exclamation-triangle", "cadastro/situacao-risco", "CAD_SITUACAO_RISCO"),
      new MenuModel("Prováveis Consequências", "fas", "exclamation-circle", "cadastro/provavel-consequencia", "CAD_PROVAVEL_CONSEQUENCIA"),
      new MenuModel("Medidas de Controle", "fas", "file-contract", "cadastro/medida-controle", "CAD_MEDIDA_CONTROLE"),
      new MenuModel("Validação", "fas", "file-contract", "cadastro/validacao", "CAD_VALIDACAO"),
    ]));

    //PGR-GRO
    this._menuItems.push(new MenuModel("PGR - GRO", "fas", "layer-group", "", "", [
      new MenuModel("IRE", "fas", "people-arrows", "pgr-gro/ire", "VISUALIZAR_IRE"),
      new MenuModel("Controle de Risco/PA", "fas", "registered", "pgr-gro/controle-risco", "CONTROLE_RISCO"),
    ]));

    //DASHBOARD
    this._menuItems.push(new MenuModel("Dashboard", "fas", "chart-line", "", "", [
      new MenuModel("Gestão dos Riscos", "fas", "chart-bar", "dashboard/gestao-riscos", "GESTAO_RISCO_DASH"),
    ]));

    //ORIENTAÇÕES
    this._menuItems.push(new MenuModel("Orientações", "fab", "wpforms", "", "", [
      new MenuModel("Orientações Gerais", "fas", "diagnoses", "orientacoes/analista-ergonomia", "ORIENTACAO_ANALISTA"),
      new MenuModel("Consulta Técnica", "fas", "clipboard-list", "orientacoes/consulta-tecnica", "CONSULTA_TECNICA"),
      new MenuModel("Fluxo e Processo", "fas", "project-diagram", "orientacoes/fluxo-processo", "FLUXO_PROCESSO"),
    ]));


    //SEGURANÇA
    this._menuItems.push(new MenuModel("Segurança", "fas", "shield-alt", "", "", [
      new MenuModel("Usuário", "fas", "users", "seguranca/usuario", "CAD_USUARIO"),
      new MenuModel("Grupo", "fas", "object-group", "seguranca/grupo", "CAD_GRUPO"),
    ]));


    //Filters the menu items according by user permission
    let userResources = this.authService.getLoggedUserResource();

    let permissionItems = new Array<MenuModel>();

    if (userResources) {
      //Gets menu items with and without access control those user has permission
      this._menuItems.forEach(f => {
        let children = f.items?.filter(m => !m.resource || userResources?.find(r => r.recurso_codigo == m.resource));
        if (children && children.length > 0) {
          let newer = { ...f };
          newer.items = new Array<MenuModel>();
          newer.items.push(...children);
          permissionItems.push(newer);
        }
      });
      this._menuItems = permissionItems;
    }
    else
      this.clearMenu();
  }

  public clearMenu() {
    this._menuItems = new Array<MenuModel>();
  }

  get getEstados(): any {

    return [
      { uf: "AC", estado: "Acre" },
      { uf: "AL", estado: "Alagoas" },
      { uf: "AM", estado: "Amazonas" },
      { uf: "AP", estado: "Amapá" },
      { uf: "BA", estado: "Bahia" },
      { uf: "CE", estado: "Ceará" },
      { uf: "DF", estado: "Distrito Federal" },
      { uf: "ES", estado: "Espírito Santo" },
      { uf: "GO", estado: "Goiás" },
      { uf: "MA", estado: "Maranhão" },
      { uf: "MG", estado: "Minas Gerais" },
      { uf: "MS", estado: "Mato Grosso do Sul" },
      { uf: "MT", estado: "Mato Grosso" },
      { uf: "PA", estado: "Pará" },
      { uf: "PB", estado: "Paraíba" },
      { uf: "PE", estado: "Pernambuco" },
      { uf: "PI", estado: "Piauí" },
      { uf: "PR", estado: "Paraná" },
      { uf: "RJ", estado: "Rio de Janeiro" },
      { uf: "RN", estado: "Rio Grande do Norte" },
      { uf: "RO", estado: "Rondônia" },
      { uf: "RR", estado: "Roraima" },
      { uf: "RS", estado: "Rio Grande do Sul" },
      { uf: "SC", estado: "Santa Catarina" },
      { uf: "SE", estado: "Sergipe" },
      { uf: "SP", estado: "São Paulo" },
      { uf: "TO", estado: "Tocantins" }
    ]

  }
}

