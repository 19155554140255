import { Component, OnInit, Input, Output, EventEmitter, Injector } from '@angular/core';
import { BaseComponent } from '../../base.component';


@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.css']
})
export class ConfirmDeleteComponent extends BaseComponent implements OnInit {

  @Output() delete = new EventEmitter<boolean>();

  @Input() showDelete: boolean = false;
  @Input() message: string = "";
  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {

  }

  cancel() {
    this.delete.emit(false);
    this.showDelete = false;
  }

  confirmDelete() {
    this.delete.emit(true);
    this.showDelete = false;
  }

}
