<div class="conteudo">
  <div fxFlex="100" fxLayout="row" fxLayout.sm='column' fxLayout.xs='column' fxLayoutGap="8px">
    <div fxHide.xs fxHide.sm fxFlex="*" fxLayout="column"> </div>
    <div fxFlex="35" fxFlex.sm='100' fxFlex.xs='100' fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="18px" class="menu-lateral">
      <span class="title">Alterar Senha</span>
      <span>Informe uma nova senha.</span>
      <form action="action-login" (submit)="onFormSubmit($event)" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="18px">
        <div fxFlex="100">
          <div>Nova Senha</div>
          <dx-text-box width="380px" [(value)]="senha"  [(mode)]="passwordMode" [showClearButton]="true" placeholder="Informe nova senha...">
            <dxi-button *ngIf="passwordMode != 'text'" name="view" location="after" [options]="passwordButton"></dxi-button>
            <dxi-button *ngIf="passwordMode == 'text'"  name="view2" location="after" [options]="passwordButtonView"></dxi-button>
            <dxi-button name="clear"></dxi-button>
            <dx-validator>
              <dxi-validation-rule type="required" message="Informe a senha!"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
        <div fxFlex="100">
          <div>Confirmar Senha</div>
          <dx-text-box width="380px" [(value)]="novaSenha" [(mode)]="passwordModeConfirm" [showClearButton]="true" placeholder="Confirme a nova senha...">
            <dxi-button *ngIf="passwordModeConfirm != 'text'" name="view" location="after" [options]="passwordButtonConfirm"></dxi-button>
            <dxi-button *ngIf="passwordModeConfirm == 'text'"  name="view2" location="after" [options]="passwordButtonViewConfirm"></dxi-button>
            <dxi-button name="clear"></dxi-button>
            <dx-validator>
                <dxi-validation-rule type="required" message="Informe a senha para confirmar!"></dxi-validation-rule>
                <dxi-validation-rule type="compare" [comparisonTarget]="passwordComparison" message="Senhas não conferem!"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
        <dx-button id="button" text="Alterar Senha" type="success" [height]="40" [useSubmitBehavior]="true" [disabled]="loading"> 
          <div *dxTemplate="let data of 'content'">
            <dx-load-indicator id="load-button" class='button-indicator'
                [visible]="loading">
            </dx-load-indicator>
            <span class='dx-button-text'>{{btnText}}</span>
          </div>
        </dx-button>
      </form>
    </div>
  </div>
</div>