import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Storage } from '../storage';
import { sprintf } from 'sprintf-js';
import { UsuarioModel } from '../models/usuario.model';
import { PermissaoModel } from '../models/permissao.model';
import { EmpresaModel } from 'src/app/cadastros/models/empresa.model';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  static getUserResource: any;

  constructor(private http: HttpClient, @Inject('API_URL') private apiUrl: any, private router: Router, private storage: Storage) {

  }

  private apiLogin: string = this.apiUrl + '/signin';
  private tokenKey: string = 'userToken';
  private userKey: string = 'user';
  private companyKey: string = 'company';
  private userResourceKey: string = 'userResources';
  private validationTimeKey: string = 'timeValid';
  private redirectUrl: string = '';


  public isLoggedIn() {
    let token = this.getAccessToken();
    let user = this.getLoggedUser();
    if (!token || !user || !user.id)
      return false;
    else {
      return true;
    }
  }

  public setRedirectUrl(url: any) {
    this.redirectUrl = url;
  }

  public getRedirectUrl() {
    return this.redirectUrl;
  }

  public setUser(user: UsuarioModel) {
    this.storage.setValue<UsuarioModel>(this.userKey, user);
  }

  public getLoggedUser(): UsuarioModel {
    let res: any = this.storage.getValue<UsuarioModel>(this.userKey);
    return res;
  }

  public setCompany(company: EmpresaModel) {
    this.storage.setValue<EmpresaModel>(this.companyKey, company);
  }

  public getCompany(): EmpresaModel {
    let res: any = this.storage.getValue<EmpresaModel>(this.companyKey);
    return res;
  }

  public getAccessToken() {
    return this.storage.getValue<string>(this.tokenKey);
  }

  public getLoggedUserResource() {
    return this.storage.getValue<PermissaoModel[]>(this.userResourceKey);
  }

  public setTimeValid() {
    let data = new Date();
    data.setHours(data.getHours() + 8);
    this.storage.setValue<Date>(this.validationTimeKey, data);
  }

  public getTimeValid() {
    return this.storage.getValue<Date>(this.validationTimeKey);
  }

  public getUser(id: number) {
    return this.http.get<UsuarioModel>(this.apiUrl.concat(sprintf('/usuarios/%u', id)));
  }

  public getUserResource(id: number, idEmpresa: number) {
    return this.http.get<any>(this.apiUrl.concat(sprintf('/permissoes/usuario/%u/%u', id, idEmpresa)));
  }

  public setUserResource(permissions: PermissaoModel[]) {
    this.storage.setValue<PermissaoModel[]>(this.userResourceKey, permissions);
  }

  public validatesUserResource(resource: string): boolean {
    if (resource && resource.trim() != '') {
      let arrRecursos = this.getLoggedUserResource();
      if (arrRecursos && arrRecursos.length > 0 && arrRecursos?.find(r => r.recurso_codigo == resource && r.ativo)) {
        return true;
      }
    }
    return false;
  }

  public setAccessToken(token: string) {
    this.storage.setValue<string>(this.tokenKey, token);
  }

  public removeTokenKey() {
    this.storage.clearValue(this.tokenKey);
  }

  public removeResource() {
    this.storage.clearValue(this.userResourceKey);
  }

  public logout() {
    this.storage.clearValue(this.tokenKey);
    this.storage.clearValue(this.userResourceKey);
    this.storage.clearValue(this.userKey);
    this.storage.clearValue(this.companyKey);
    this.storage.clearValue(this.validationTimeKey);
    this.router.navigate(['/login']);
  }

  public authenticateUser(user: string, password: string) {
    let options = { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }) };
    return this.http.post(this.apiLogin, { login: user, senha: password }, options);
  }
}

