export class MenuModel{
  constructor(
    public title?: string,
    public faImage?: string,
    public image?: string,
    public route?: string,
    public resource?: string,
    public items?: MenuModel[]
    ) { }
}
