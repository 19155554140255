import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from 'src/app/common/services/authentication.service';
import { GlobalService } from 'src/app/common/services//global.service';
import { retry, catchError } from 'rxjs/operators';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthenticationService, private globalService: GlobalService) { }

  addToken(req: HttpRequest<any>): HttpRequest<any> {
    let type = req.headers.get("enctype");
    if (type == "multipart/form-data")
      return req.clone({
        setHeaders:
        {
          'Authorization': 'Bearer ' + this.auth.getAccessToken(),
          'Access-Control-Allow-Origin': '*'
        }
      });
    return req.clone({
      setHeaders:
      {
        'Authorization': 'Bearer ' + this.auth.getAccessToken(),
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    //Verify Token lifetime
    let currentDate = new Date();
    let validationDate = new Date(this.auth.getTimeValid()!);

    //Includes token in all request, except the login request
    if (!request.url.includes("login")) {
      request = this.addToken(request);
    }

    if (currentDate > validationDate && !request.url.includes("login")) {
      this.globalService.clearMenu();
      this.auth.logout();

      return next.handle(request);
    }
    else {
      return next.handle(request)
        .pipe(
          retry(1),
          catchError((error: HttpErrorResponse) => {
            if (error.status == 401 && !request.url.includes("login")) {
              this.auth.logout();
            }
            else if (error.status == 403) {
              this.globalService.clearMenu();
              this.auth.logout();
            }
            else {
              return throwError(error);
            }

            return next.handle(request);
          })
        )
    }
  }

  addAuthenticationToken(request: any) {
    const accessToken = this.auth.getAccessToken();
    if (!accessToken) {
      return request;
    }
    return this.addToken(request);
  }
}
