<div style="height: 100%;">
  <div fxFlex="100" fxLayout="row" fxLayout.sm='column' fxLayout.xs='column' fxLayoutGap="8px">
    <div fxHide.xs fxHide.sm fxFlex="*" fxLayout="column" class="conteudo"></div>
    <div *ngIf="!showSelecionarEmpresa" fxFlex="35" fxFlex.sm='100' fxFlex.xs='100' fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="18px" class="menu-lateral">
      <span class="title">Bem-vindo</span>
      <span>Efetue seu login para ter acesso ao sistema</span>
      <form action="action-login" (submit)="onFormSubmit($event)" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="18px">
        <div fxFlex="100">
          <div>Login</div>
          <dx-text-box width="380px" [(value)]="txtLogin" [showClearButton]="true" placeholder="Informe o login...">
            <dxi-button name="user" location="before" [options]="userIcon"></dxi-button>
            <dx-validator>
              <dxi-validation-rule type="required" message="Informe o login!"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
        <div fxFlex="100">
          <div>Senha</div>
          <dx-text-box  width="380px" [(value)]="senha" [(mode)]="passwordMode" [showClearButton]="true" placeholder="Informe a senha...">
            <dxi-button name="user" location="before" [options]="passwordIcon"></dxi-button>
            <dxi-button *ngIf="passwordMode != 'text'" name="view" location="after" [options]="passwordButton"></dxi-button>
            <dxi-button *ngIf="passwordMode == 'text'"  name="view2" location="after" [options]="passwordButtonView"></dxi-button>
            <dxi-button name="clear"></dxi-button>
            <dx-validator>
              <dxi-validation-rule type="required" message="Informe a senha!"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
        <dx-button id="button" text="Autenticar" type="success" [useSubmitBehavior]="true" [disabled]="loadingLogin">
          <div *dxTemplate="let data of 'content'">
            <dx-load-indicator id="load-button" class='button-indicator' [visible]="loadingLogin">
            </dx-load-indicator>
            <span class='dx-button-text'>{{btnText}}</span>
          </div>
        </dx-button>
        <!-- <div fxFlex="100" fxLayoutAlign="end end" fxLayoutGap="18px">
          <dx-button text="Esqueci a senha" stylingMode="text" type="normal" (onClick)="solicitarSenha()"> </dx-button>
        </div> -->
      </form>
      <span id="versao">{{versao}}</span>
    </div>
    <div *ngIf="showSelecionarEmpresa" fxFlex="35" fxFlex.sm='100' fxFlex.xs='100' fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="18px" class="menu-lateral">
      <span>Selecione a empresa para ter acesso ao sistema</span>
      <div fxLayoutAlign="center stretch" fxLayoutGap="18px" class="div-empresas">
        <dx-list #list [dataSource]="dadosEmpresas" [showSelectionControls]="false" (onItemClick)="setEmpresa($event)">
          <div *dxTemplate="let data of 'item'">
              <div style="white-space: initial;">{{data.razao_social}}</div>
          </div>
        </dx-list>
      </div>
    </div>
  </div>
</div>