<div class="container">
  <dx-toolbar *ngIf="!authService.getLoggedUser()?.nova_senha && (global.showMenu || global.showUser)" id="mainTool">

    <dxi-item location="before" widget="dxButton" *ngIf="authService.getCompany().logo">
      <div *dxTemplate>
        <div class="logo-site">
          <h1>
            <a [routerLink]="['']" [ngStyle]="{'background-image': getLogo()}"></a>
          </h1>
        </div>
      </div>
    </dxi-item>

    <dxi-item *ngIf="global.showMenu" location="before" widget="dxButton" [options]="menuButton">
    </dxi-item>

    <dxi-item location="center">
      <div *dxTemplate>
        <div style="cursor: pointer;">
          <a class='toolbar-label' [routerLink]="['']" [ngStyle]="{'font-size': util.isMobile? 'small' : '16px', 'margin-left': util.isMobile? '50px': ''}">
            <span style="display: block;" *ngIf="authService.getCompany()?.razao_social">Gestão de Risco Nomos</span>
            <span style="font-size: 12px;" *ngIf="authService.getCompany()?.razao_social">{{ authService.getCompany()?.razao_social }}</span>
          </a>
        </div>
      </div>
    </dxi-item>

    <dxi-item *ngIf="global.showUser" location="after" widget="dxButton">
      <div *dxTemplate>
        <div class="user-action" style="cursor: pointer;">
          <a id="btnUser" (click)="showUserData()">
            <span *ngIf="!util.isMobile" style="margin-right: 10px;">{{ authService.getLoggedUser()?.nome }}</span>
            <fa-icon [icon]="['fas', 'user']"></fa-icon>
          </a>
        </div>
      </div>
    </dxi-item>

  </dx-toolbar>

  <dx-drawer #drawer openedStateMode="overlap" position="left" revealMode="slide" template="template" [(opened)]="showMenu" [closeOnOutsideClick]="true" [shading]="true" [class.full-height]="!authService.getLoggedUser()?.nova_senha && (global.showMenu || global.showUser)">
    <div *dxTemplate="let data of 'template'" style="width: 280px; background-color: white;">
      <dx-list [dataSource]="global.menuItems" [grouped]="true" [collapsibleGroups]="true" [hoverStateEnabled]="true" [activeStateEnabled]="true" [focusStateEnabled]="true" itemTemplate="data"> 
        
        <div *dxTemplate="let item of 'group'">
          <div fxFlex="15" fxLayoutAlign="center">
            <fa-icon *ngIf="item.image" [icon]="[item.faImage, item.image]"></fa-icon>
          </div>
          <div fxFlex="85">
            {{ item.title }}
          </div>
        </div>

        <div *dxTemplate="let child of 'data'" (click)="navigate(child.route)">
          <div fxFlex="15" fxLayoutAlign="center">
            <fa-icon *ngIf="child.image" [icon]="[child.faImage, child.image]"></fa-icon>
          </div>
          <div fxFlex="85" style="white-space: initial;">
            {{ child.title }}
          </div>
        </div>
      </dx-list>
    </div>

    <div id="content" class="dx-theme-background-color content">
      <router-outlet></router-outlet>
    </div>
  </dx-drawer>
  
  <dx-load-panel shadingColor="rgba(128,128,128,.5)" [(visible)]="loading" [showIndicator]="true" [showPane]="true"
    [shading]="true" [closeOnOutsideClick]="false" message="Aguarde, carregando...">
  </dx-load-panel>

  <dx-popover target="#btnUser" position="top" [width]="250" [shading]="true" shadingColor="rgba(0, 0, 0, 0.2)"
    [(visible)]="showPopUser">
    <div *dxTemplate="let data of 'content'">

      <dx-list [hoverStateEnabled]="true" [activeStateEnabled]="true" [focusStateEnabled]="false">
        <dxi-item>
          <span><b>{{ authService.getLoggedUser()?.nome }}</b></span>
        </dxi-item>
        <dxi-item>
          <span style="white-space: initial;">{{ getGruposUsuario() }}</span>
        </dxi-item>
        <dxi-item>
          <div fxFlex="100">
            <span style="font-size: 10px;">Empresa</span>
            <dx-select-box [dataSource]="dadosEmpresas" displayExpr="razao_social" valueExpr="id" [(value)]="idEmpresaSelecionada"
              [searchEnabled]="true" searchMode="contains" searchExpr="razao_social" [disabled]="dadosEmpresas.length == 1" (onValueChanged)="setEmpresa()"> 
               <div *dxTemplate="let data of 'item'">
                    <div style="white-space: initial;">{{data.razao_social}}</div>
                </div>
            </dx-select-box>
          </div>
        </dxi-item>
        <dxi-item (click)="logout()">
          <div fxFlex="15" fxLayoutAlign="center">
            <fa-icon [icon]="['fas', 'user-lock']"></fa-icon>
          </div>
          <div fxFlex="15">Sair</div>
          <div fxFlex="70" style="text-align: right; font-size: x-small; position: relative; top: 3px;">{{versao}}</div>
        </dxi-item>
      </dx-list>

    </div>
  </dx-popover>
</div>