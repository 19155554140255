import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe, DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { environment } from 'src/environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  DxTreeViewModule,
  DxToolbarModule, DxButtonModule, DxLoadIndicatorModule, DxDrawerModule,
  DxListModule, DxPopoverModule, DxTextBoxModule, DxValidatorModule, DxLoadPanelModule, DxSelectBoxModule,
} from 'devextreme-angular';
import { AppRootComponent } from './app-root.component';
import { AppRoutingModule } from './app-root-routing.module';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpAuthInterceptor } from '../common/interceptor/http-auth-interceptor';
import { AuthGuardService } from './guards/auth-guard.service';
import { AppComponentsModule } from '../common/components/app-components.module';
import { SegurancaService } from '../common/services/seguranca.service';
import { SenhaNovaComponent } from './senha-nova/senha-nova.component';
import { EmpresaService } from '../cadastros/services/empresa.service';


@NgModule({
  declarations: [
    AppRootComponent,
    HomeComponent,
    LoginComponent,
    UnauthorizedComponent,
    SenhaNovaComponent
  ],
  imports: [
    // Angular
    CommonModule,
    BrowserModule,
    FontAwesomeModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,

    // DevExtreme
    DxTreeViewModule,
    DxToolbarModule,
    DxButtonModule,
    DxLoadIndicatorModule,
    DxDrawerModule,
    DxListModule,
    DxPopoverModule,
    DxTextBoxModule,
    DxValidatorModule,
    DxLoadPanelModule,
    DxSelectBoxModule,

    //App
    AppComponentsModule,
    AppRoutingModule
  ],
  exports: [
    AppRootComponent
  ],
  providers: [
    DatePipe,
    DecimalPipe,
    SegurancaService,
    EmpresaService,
    { provide: 'API_URL', useFactory: getApiUrl },
    { provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true },
    [AuthGuardService]
  ],
})
export class AppRootModule { }

// ROTAS API
export function getApiUrl() {
    // TESTE
    return 'https://api-tst.solucoesintegradas.app.br';
  }
  
