import { Component, OnInit, AfterViewInit, ViewChild, Inject } from '@angular/core';
import { Util } from '../common/util';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { AuthenticationService } from '../common/services/authentication.service';
import { GlobalService } from '../common/services/global.service';
import { EmpresaModel } from '../cadastros/models/empresa.model';
import { EmpresaService } from '../cadastros/services/empresa.service';
import { UsuarioModel } from '../common/models/usuario.model';
import { DxDrawerComponent } from 'devextreme-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app-root.component.html',
  styleUrls: ['./app-root.component.css']
})

export class AppRootComponent implements OnInit, AfterViewInit {
  @ViewChild('drawer', { static: false }) drawer!: DxDrawerComponent;
  loading: boolean = false;
  menuButton: any;
  showPopUser: boolean = false;
  showMenu: boolean = false;
  dadosEmpresas: EmpresaModel[] = [];
  idEmpresaSelecionada!: number;
  usuarioLogado!: UsuarioModel;
  versao?: string;
    

  constructor(public util: Util, private router: Router, public authService: AuthenticationService, public global: GlobalService, public empresaService: EmpresaService, @Inject('API_URL') private apiUrl: string) {
    this.loading = true;
    this.showMenu = false;
    this.global.versaoSistema
    this.menuButton = {
      icon: 'menu',
      stylingMode: "text",
      type: "normal",
      class: "toolbar-menu",
      onClick: () => {
        this.drawer.instance.repaint();
        this.drawer.instance.toggle();
      }
    };
  }
  ngOnInit() {
    this.global.setMenu();
    this.versao = this.global.versaoSistema;
    
  }

  ngAfterViewInit(): void {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.usuarioLogado = this.authService.getLoggedUser();

          if (this.usuarioLogado && this.usuarioLogado.id) {
            this.empresaService.buscarPorUsuario(this.usuarioLogado.id!)
              .subscribe(data => {
                this.dadosEmpresas = data.filter(f => f.ativo);
                this.idEmpresaSelecionada = this.authService.getCompany()?.id!;
              });
          }
          window.setTimeout(() => {
            this.loading = true;
          }, 50);
        }
        else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          window.setTimeout(() => {
            this.loading = false;
          }, 80);
        }
      });
  }

  showUserData() {
    this.showPopUser = true;
  }

  logout() {
    this.showPopUser = false;
    this.showMenu = false;
    this.global.clearMenu();
    this.authService.logout();
    this.authService.setRedirectUrl('');
  }


  navigate(route: any) {
    this.showMenu = false;
    this.authService.setRedirectUrl(route);
    window.setTimeout(() => this.router.navigate([route]), 500);
  }

  setEmpresa() {
    this.loading = true;
    this.showMenu = false;
    this.global.clearMenu();
    this.authService.removeResource();
    this.authService.setCompany(this.dadosEmpresas.find(f => f.id == this.idEmpresaSelecionada)!)

    this.authService.getUserResource(this.usuarioLogado.id!, this.idEmpresaSelecionada)
      .subscribe((permissoes: any) => {
        if (permissoes && permissoes.length > 0) {
          this.authService.setUserResource(permissoes);
          this.global.setMenu();
          window.setTimeout(() => {
            this.router.navigate(["/home"]);
          }, 800);
        }
        else {
          this.util.showError("Usuário sem permissão para acessar o sistema.");
          this.global.clearMenu();
          window.setTimeout(() => {
            this.router.navigate(["/unauthorized"]);
          }, 800);
        }

        this.loading = false;
      },
        error => {
          this.util.showError("Falha ao autenticar o usuário, tente novamente.");
          this.loading = false;
        });
  }

  getGruposUsuario() {
    let result: string = "";
    let recursos = this.authService.getLoggedUserResource();
    let gruposDistintos = [...new Set(recursos?.map(item => item.grupo))]
    if (gruposDistintos.length > 1)
      result = "Grupos: " + gruposDistintos.join(", ");
    else if (gruposDistintos.length)
      result = "Grupo: " + gruposDistintos[0];
    else
      result = "Nenhum grupo associado";
    return result
  }

  getLogo() {
    let result = "";
    if (this.authService.getCompany().logo)
      result = `url(${this.apiUrl}/${this.authService.getCompany().logo})`
    return result;
  }

}
