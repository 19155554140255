import { Component, OnInit, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { BaseComponent } from "src/app/common/base.component";
import { UsuarioModel } from "src/app/common/models/usuario.model";
import { GlobalService } from "src/app/common/services/global.service";
import { SegurancaService } from "src/app/common/services/seguranca.service";

@Component({
  selector: "app-senha-nova",
  templateUrl: "./senha-nova.component.html",
  styleUrls: ["./senha-nova.component.css"]
})
export class SenhaNovaComponent extends BaseComponent implements OnInit {
  senha: string = "";
  novaSenha: string = "";
  btnText: string = "";
  loading: boolean = false;
  usuario?: UsuarioModel;
  passwordMode: string = "";
  passwordButton: any;
  passwordButtonView: any;
  passwordModeConfirm: string = "";
  passwordButtonConfirm: any;
  passwordButtonViewConfirm: any;

  constructor(injector: Injector, private router: Router, private segurancaService: SegurancaService, public global: GlobalService) {
    super(injector);
    this.passwordMode = 'password';
    this.passwordButton = {
      icon: "data: image/svg+xml; base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGFyaWEtaGlkZGVuPSJ0cnVlIiBmb2N1c2FibGU9ImZhbHNlIiBkYXRhLXByZWZpeD0iZmFzIiBkYXRhLWljb249ImV5ZSIgcm9sZT0iaW1nIiB3aWR0aD0iNTc2IiBoZWlnaHQ9IjUxMiIgc3R5bGU9IiI+PHJlY3QgaWQ9ImJhY2tncm91bmRyZWN0IiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB4PSIwIiB5PSIwIiBmaWxsPSJub25lIiBzdHJva2U9Im5vbmUiLz48ZyBjbGFzcz0iY3VycmVudExheWVyIiBzdHlsZT0iIj48dGl0bGU+TGF5ZXIgMTwvdGl0bGU+PHBhdGggZmlsbD0iIzk5OTk5OSIgZD0iTTU3Mi41MiAyNDEuNEM1MTguMjkgMTM1LjU5IDQxMC45MyA2NCAyODggNjRTNTcuNjggMTM1LjY0IDMuNDggMjQxLjQxYTMyLjM1IDMyLjM1IDAgMCAwIDAgMjkuMTlDNTcuNzEgMzc2LjQxIDE2NS4wNyA0NDggMjg4IDQ0OHMyMzAuMzItNzEuNjQgMjg0LjUyLTE3Ny40MWEzMi4zNSAzMi4zNSAwIDAgMCAwLTI5LjE5ek0yODggNDAwYTE0NCAxNDQgMCAxIDEgMTQ0LTE0NCAxNDMuOTMgMTQzLjkzIDAgMCAxLTE0NCAxNDR6bTAtMjQwYTk1LjMxIDk1LjMxIDAgMCAwLTI1LjMxIDMuNzkgNDcuODUgNDcuODUgMCAwIDEtNjYuOSA2Ni45QTk1Ljc4IDk1Ljc4IDAgMSAwIDI4OCAxNjB6IiBpZD0ic3ZnXzEiIGNsYXNzPSJzZWxlY3RlZCIgZmlsbC1vcGFjaXR5PSIxIi8+PC9nPjwvc3ZnPg==",
      type: "normal",
      stylingMode: "text",
      color: "#ddd",
      onClick: () => {
        this.passwordMode = this.passwordMode === "text" ? "password" : "text";
      }
    }
    this.passwordButtonView = {
      icon: "data: image/svg+xml; base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGFyaWEtaGlkZGVuPSJ0cnVlIiBmb2N1c2FibGU9ImZhbHNlIiBkYXRhLXByZWZpeD0iZmFzIiBkYXRhLWljb249ImV5ZS1zbGFzaCIgcm9sZT0iaW1nIiB3aWR0aD0iNjQwIiBoZWlnaHQ9IjUxMiIgc3R5bGU9IiI+PHJlY3QgaWQ9ImJhY2tncm91bmRyZWN0IiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB4PSIwIiB5PSIwIiBmaWxsPSJub25lIiBzdHJva2U9Im5vbmUiLz48ZyBjbGFzcz0iY3VycmVudExheWVyIiBzdHlsZT0iIj48dGl0bGU+TGF5ZXIgMTwvdGl0bGU+PHBhdGggZmlsbD0iIzk5OTk5OSIgZD0iTTMyMCA0MDBjLTc1Ljg1IDAtMTM3LjI1LTU4LjcxLTE0Mi45LTEzMy4xMUw3Mi4yIDE4NS44MmMtMTMuNzkgMTcuMy0yNi40OCAzNS41OS0zNi43MiA1NS41OWEzMi4zNSAzMi4zNSAwIDAgMCAwIDI5LjE5Qzg5LjcxIDM3Ni40MSAxOTcuMDcgNDQ4IDMyMCA0NDhjMjYuOTEgMCA1Mi44Ny00IDc3Ljg5LTEwLjQ2TDM0NiAzOTcuMzlhMTQ0LjEzIDE0NC4xMyAwIDAgMS0yNiAyLjYxem0zMTMuODIgNTguMWwtMTEwLjU1LTg1LjQ0YTMzMS4yNSAzMzEuMjUgMCAwIDAgODEuMjUtMTAyLjA3IDMyLjM1IDMyLjM1IDAgMCAwIDAtMjkuMTlDNTUwLjI5IDEzNS41OSA0NDIuOTMgNjQgMzIwIDY0YTMwOC4xNSAzMDguMTUgMCAwIDAtMTQ3LjMyIDM3LjdMNDUuNDYgMy4zN0ExNiAxNiAwIDAgMCAyMyA2LjE4TDMuMzcgMzEuNDVBMTYgMTYgMCAwIDAgNi4xOCA1My45bDU4OC4zNiA0NTQuNzNhMTYgMTYgMCAwIDAgMjIuNDYtMi44MWwxOS42NC0yNS4yN2ExNiAxNiAwIDAgMC0yLjgyLTIyLjQ1em0tMTgzLjcyLTE0MmwtMzkuMy0zMC4zOEE5NC43NSA5NC43NSAwIDAgMCA0MTYgMjU2YTk0Ljc2IDk0Ljc2IDAgMCAwLTEyMS4zMS05Mi4yMUE0Ny42NSA0Ny42NSAwIDAgMSAzMDQgMTkyYTQ2LjY0IDQ2LjY0IDAgMCAxLTEuNTQgMTBsLTczLjYxLTU2Ljg5QTE0Mi4zMSAxNDIuMzEgMCAwIDEgMzIwIDExMmExNDMuOTIgMTQzLjkyIDAgMCAxIDE0NCAxNDRjMCAyMS42My01LjI5IDQxLjc5LTEzLjkgNjAuMTF6IiBpZD0ic3ZnXzEiIGNsYXNzPSJzZWxlY3RlZCIgZmlsbC1vcGFjaXR5PSIxIi8+PC9nPjwvc3ZnPg==",
      type: "normal",
      stylingMode: "text",
      color: "#ddd",
      onClick: () => {
        this.passwordMode = this.passwordMode === "text" ? "password" : "text";
      }
    }

    this.passwordModeConfirm = 'password';
    this.passwordButtonConfirm = {
      icon: "data: image/svg+xml; base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGFyaWEtaGlkZGVuPSJ0cnVlIiBmb2N1c2FibGU9ImZhbHNlIiBkYXRhLXByZWZpeD0iZmFzIiBkYXRhLWljb249ImV5ZSIgcm9sZT0iaW1nIiB3aWR0aD0iNTc2IiBoZWlnaHQ9IjUxMiIgc3R5bGU9IiI+PHJlY3QgaWQ9ImJhY2tncm91bmRyZWN0IiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB4PSIwIiB5PSIwIiBmaWxsPSJub25lIiBzdHJva2U9Im5vbmUiLz48ZyBjbGFzcz0iY3VycmVudExheWVyIiBzdHlsZT0iIj48dGl0bGU+TGF5ZXIgMTwvdGl0bGU+PHBhdGggZmlsbD0iIzk5OTk5OSIgZD0iTTU3Mi41MiAyNDEuNEM1MTguMjkgMTM1LjU5IDQxMC45MyA2NCAyODggNjRTNTcuNjggMTM1LjY0IDMuNDggMjQxLjQxYTMyLjM1IDMyLjM1IDAgMCAwIDAgMjkuMTlDNTcuNzEgMzc2LjQxIDE2NS4wNyA0NDggMjg4IDQ0OHMyMzAuMzItNzEuNjQgMjg0LjUyLTE3Ny40MWEzMi4zNSAzMi4zNSAwIDAgMCAwLTI5LjE5ek0yODggNDAwYTE0NCAxNDQgMCAxIDEgMTQ0LTE0NCAxNDMuOTMgMTQzLjkzIDAgMCAxLTE0NCAxNDR6bTAtMjQwYTk1LjMxIDk1LjMxIDAgMCAwLTI1LjMxIDMuNzkgNDcuODUgNDcuODUgMCAwIDEtNjYuOSA2Ni45QTk1Ljc4IDk1Ljc4IDAgMSAwIDI4OCAxNjB6IiBpZD0ic3ZnXzEiIGNsYXNzPSJzZWxlY3RlZCIgZmlsbC1vcGFjaXR5PSIxIi8+PC9nPjwvc3ZnPg==",
      type: "normal",
      stylingMode: "text",
      hoverStateEnabled: false,
      onClick: () => {
        this.passwordModeConfirm = this.passwordModeConfirm === "text" ? "password" : "text";
      }
    }
    this.passwordButtonViewConfirm = {
      icon: "data: image/svg+xml; base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGFyaWEtaGlkZGVuPSJ0cnVlIiBmb2N1c2FibGU9ImZhbHNlIiBkYXRhLXByZWZpeD0iZmFzIiBkYXRhLWljb249ImV5ZS1zbGFzaCIgcm9sZT0iaW1nIiB3aWR0aD0iNjQwIiBoZWlnaHQ9IjUxMiIgc3R5bGU9IiI+PHJlY3QgaWQ9ImJhY2tncm91bmRyZWN0IiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB4PSIwIiB5PSIwIiBmaWxsPSJub25lIiBzdHJva2U9Im5vbmUiLz48ZyBjbGFzcz0iY3VycmVudExheWVyIiBzdHlsZT0iIj48dGl0bGU+TGF5ZXIgMTwvdGl0bGU+PHBhdGggZmlsbD0iIzk5OTk5OSIgZD0iTTMyMCA0MDBjLTc1Ljg1IDAtMTM3LjI1LTU4LjcxLTE0Mi45LTEzMy4xMUw3Mi4yIDE4NS44MmMtMTMuNzkgMTcuMy0yNi40OCAzNS41OS0zNi43MiA1NS41OWEzMi4zNSAzMi4zNSAwIDAgMCAwIDI5LjE5Qzg5LjcxIDM3Ni40MSAxOTcuMDcgNDQ4IDMyMCA0NDhjMjYuOTEgMCA1Mi44Ny00IDc3Ljg5LTEwLjQ2TDM0NiAzOTcuMzlhMTQ0LjEzIDE0NC4xMyAwIDAgMS0yNiAyLjYxem0zMTMuODIgNTguMWwtMTEwLjU1LTg1LjQ0YTMzMS4yNSAzMzEuMjUgMCAwIDAgODEuMjUtMTAyLjA3IDMyLjM1IDMyLjM1IDAgMCAwIDAtMjkuMTlDNTUwLjI5IDEzNS41OSA0NDIuOTMgNjQgMzIwIDY0YTMwOC4xNSAzMDguMTUgMCAwIDAtMTQ3LjMyIDM3LjdMNDUuNDYgMy4zN0ExNiAxNiAwIDAgMCAyMyA2LjE4TDMuMzcgMzEuNDVBMTYgMTYgMCAwIDAgNi4xOCA1My45bDU4OC4zNiA0NTQuNzNhMTYgMTYgMCAwIDAgMjIuNDYtMi44MWwxOS42NC0yNS4yN2ExNiAxNiAwIDAgMC0yLjgyLTIyLjQ1em0tMTgzLjcyLTE0MmwtMzkuMy0zMC4zOEE5NC43NSA5NC43NSAwIDAgMCA0MTYgMjU2YTk0Ljc2IDk0Ljc2IDAgMCAwLTEyMS4zMS05Mi4yMUE0Ny42NSA0Ny42NSAwIDAgMSAzMDQgMTkyYTQ2LjY0IDQ2LjY0IDAgMCAxLTEuNTQgMTBsLTczLjYxLTU2Ljg5QTE0Mi4zMSAxNDIuMzEgMCAwIDEgMzIwIDExMmExNDMuOTIgMTQzLjkyIDAgMCAxIDE0NCAxNDRjMCAyMS42My01LjI5IDQxLjc5LTEzLjkgNjAuMTF6IiBpZD0ic3ZnXzEiIGNsYXNzPSJzZWxlY3RlZCIgZmlsbC1vcGFjaXR5PSIxIi8+PC9nPjwvc3ZnPg==",
      type: "normal",
      stylingMode: "text",
      hoverStateEnabled: false,
      onClick: () => {
        this.passwordModeConfirm = this.passwordModeConfirm === "text" ? "password" : "text";
      }
    }
  }

  ngOnInit() {
    this.senha = "";
    this.novaSenha = "";
    this.btnText = "Alterar Senha";
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(["/login"]);
    }

    this.usuario = this.authService.getLoggedUser();
  }

  onFormSubmit = (e: any) => {
    this.redefinirSenha();
    e.preventDefault();
  }

  redefinirSenha() {
    this.loading = true;
    this.btnText = "Alterando Senha";
    let user: any = this.usuario;
    user.senha = this.senha;
    user.nova_senha = false;
    this.segurancaService.atualizarSenhaUsuario(user).subscribe((data: UsuarioModel[]) => {
      this.loading = false;
      this.btnText = "Alterar Senha";
      user.senha = "";
      this.authService.setUser(user);
      this.util.showSuccess("Senha alterada com sucesso.");
      this.global.setMenu();
      window.setTimeout(() => {
        this.router.navigate(["/home"]);
      }, 800);
    },
      error => {
        this.loading = false;
        this.btnText = "Alterar Senha";
        this.util.showError(error);
      });
  }

  passwordComparison = () => {
    return this.senha;
  }

}