import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { sprintf } from 'sprintf-js';
import { EmpresaModel } from '../models/empresa.model';
import { EnderecoModel } from '../models/endereco.model';


@Injectable()
export class EmpresaService {

  constructor(private http: HttpClient, @Inject('API_URL') private apiUrl: string) {
  }

  private api: string = this.apiUrl + '/empresas';
  private apiPorUsuario: string = this.apiUrl + '/empresasUsuario';
  private apiEmpresaGrupo: string = this.apiUrl + '/empresaGrupo';
  private apiUsuarioEmpresa: string = this.apiUrl + '/usuarioEmpresa';
  private apiEndereco: string = this.apiUrl + '/enderecos';

  public buscarTodos() {
    return this.http.get<EmpresaModel[]>(this.api);
  }

  public cadastrar(empresa: EmpresaModel) {
    return this.http.post<EmpresaModel>(this.api, empresa);
  }

  public atualizar(empresa: EmpresaModel) {
    return this.http.put<EmpresaModel>(this.api, empresa);
  }

  public remover(id: number) {
    return this.http.delete<any>(sprintf(this.api.concat('/%u'), id));
  }

  public buscarDetalhe(id: number) {
    return this.http.get<EmpresaModel>(sprintf(this.api.concat('/%u'), id));
  }

  public buscarPorUsuario(id: number) {
    return this.http.get<EmpresaModel[]>(sprintf(this.apiPorUsuario.concat('/%u'), id));
  }

  ////////////////////////////////////EMPRESA GRUPO\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  public buscarEmpresaGrupo() {
    return this.http.get<any[]>(this.apiEmpresaGrupo);
  }

  public removerEmpresaGrupo(id: number) {
    return this.http.delete<any>(sprintf(this.apiEmpresaGrupo.concat('/%u'), id));
  }

  public cadastrarEmpresaGrupo(empresaGrupo: any) {
    return this.http.post<any>(this.apiEmpresaGrupo, empresaGrupo);
  }

  ////////////////////////////////////USUÁRIO EMPRESA\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  public buscarUsuarioEmpresa() {
    return this.http.get<any[]>(this.apiUsuarioEmpresa);
  }

  public removerUsuarioEmpresa(id: number) {
    return this.http.delete<any>(sprintf(this.apiUsuarioEmpresa.concat('/%u'), id));
  }

  public cadastrarUsuarioEmpresa(usuarioEmpresa: any) {
    return this.http.post<any>(this.apiUsuarioEmpresa, usuarioEmpresa);
  }

  ////////////////////////////////////ENDEREÇO\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  public cadastrarEndereco(endereco: EnderecoModel) {
    return this.http.post<number>(this.apiEndereco, endereco);
  }

  public atualizarEndereco(endereco: EnderecoModel) {
    return this.http.put<any>(this.apiEndereco, endereco);
  }

  public buscarDetalheEndereco(id: number) {
    return this.http.get<EnderecoModel>(sprintf(this.apiEndereco.concat('/%u'), id));
  }

  public uploadArquivo(file: any, id: number) {
    const formData = new FormData();
    formData.append('file', file);

    const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });

    return this.http.post<any>(sprintf(this.api.concat('/upload/%u'), id), formData, { headers });
  }

  public processarPlanilha(file: any) {
    const formData = new FormData();
    formData.append('file', file);

    const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });

    return this.http.post<any>(sprintf(this.api.concat('/processarPlanilha')), formData, { headers });
  }
}

