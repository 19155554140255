<dx-popup [width]="util.isMobile ? '95%' : 380" height="202px" [showTitle]="true" title="Excluir" [dragEnabled]="false" 
[closeOnOutsideClick]="false" [visible]="showDelete" (onHiding)="cancel()">

  <div *dxTemplate="let data of 'content'" fxLayout="column" fxLayoutGap="12px">
    <div fxLayout="column" fxLayoutGap="8px" style="min-height: 70px; padding-top: 12px; padding-left: 12px">
      <div><span [innerHTML]="message"></span></div>
      <span>&nbsp;</span>
    </div>
    <div fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="end center" style="padding-right: 12px">
      <dx-button type="success" text="Sim" width="80px" (onClick)="confirmDelete()"></dx-button>
      <dx-button type="normal" text="Não" width="80px" (onClick)="cancel()"></dx-button>
    </div>
  </div>
</dx-popup>
