import { Component, OnInit, Input, Output, EventEmitter, Injector } from '@angular/core';
import { BaseComponent } from '../../base.component';


@Component({
  selector: 'app-confirm-action',
  templateUrl: './confirm-action.component.html',
  styleUrls: ['./confirm-action.component.css']
})
export class ConfirmActionComponent extends BaseComponent implements OnInit {

  @Input() message: string = "";
  @Output() confirm = new EventEmitter<boolean>();

  @Input() showConfirm: boolean = false;
  titleMessage: string = "";
  constructor(injector: Injector) {
    super(injector);
  }
  ngOnInit() {

  }

  cancel() {
    this.confirm.emit(false);
    this.showConfirm = false;
  }

  confirmAction() {
    this.confirm.emit(true);
    this.showConfirm = false;
  }

}
