import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot, Route, UrlSegment } from '@angular/router';
import { AuthenticationService } from 'src/app/common/services/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) { }

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    const path = segments.reduce((path, currentSegment) => {
      return path + '/' + currentSegment.path;
    }, '');
    return true;
  }

  canActivate(activatedRouter: ActivatedRouteSnapshot): boolean {
    let resource = activatedRouter.data.resource as string;

    let isLoggedIn = this.checkAuthentication(activatedRouter.routeConfig?.path || '');

    if (isLoggedIn)
      return this.checkPermissions(resource);
    else
      return false;
  }

  canActivateChild(activatedRouter: ActivatedRouteSnapshot): boolean {
    return this.canActivate(activatedRouter);
  }

  checkAuthentication(path: string): boolean {
    const isLoggedIn = this.authService.isLoggedIn();

    if (!isLoggedIn)
      this.router.navigate(['/login']);

    return isLoggedIn;
  }

  checkPermissions(resource: string): boolean {
    let userPermissions = this.authService.validatesUserResource(resource);

    if (!userPermissions) {
      this.router.navigate(['/unauthorized']);
      return false
    }
    else
      return true;
  }

}
