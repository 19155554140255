import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  DxDateBoxModule,
  DxLoadIndicatorModule,
  DxButtonModule,
  DxDropDownBoxModule,
  DxTreeViewModule,
  DxSelectBoxModule,
  DxPopupModule,
  DxLoadPanelModule,
  DxDropDownButtonModule,
  DxActionSheetModule,
  DxTextBoxModule,
  DxButtonGroupModule
} from 'devextreme-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { SpinnerLoadingComponent } from './spinner-loading/spinner-loading.component';
import { ConfirmDeleteComponent } from './confirm-delete/confirm-delete.component';
import { ConfirmActionComponent } from './confirm-action/confirm-action.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,

    DxDateBoxModule,
    DxDropDownBoxModule,
    DxTreeViewModule,
    DxSelectBoxModule,
    DxPopupModule,
    DxLoadIndicatorModule,
    DxLoadPanelModule,
    DxButtonModule,
    DxDropDownButtonModule,
    DxActionSheetModule,
    DxTextBoxModule,
    DxButtonGroupModule,
    ReactiveFormsModule,
    FontAwesomeModule,
  ],
  declarations: [
    ToolbarComponent, SpinnerLoadingComponent, ConfirmDeleteComponent, ConfirmActionComponent
  ],
  exports: [
    ToolbarComponent, SpinnerLoadingComponent, ConfirmDeleteComponent, ConfirmActionComponent
  ]
})
export class AppComponentsModule {

}
