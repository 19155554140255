import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { sprintf } from 'sprintf-js';
import { UsuarioModel } from 'src/app/common/models/usuario.model';
import { GrupoModel } from '../models/grupo.model';
import { UsuarioGrupoModel } from '../models/usuario-grupo.model';
import { RecursoModel } from 'src/app/common/models/recurso.model';
import { PermissaoModel } from '../models/permissao.model';


@Injectable()
export class SegurancaService {

  constructor(private http: HttpClient, @Inject('API_URL') private apiUrl: string) {
  }

  private apiUsuario: string = this.apiUrl + '/usuarios';
  private apiGrupo: string = this.apiUrl + '/grupos';
  private apiRecurso: string = this.apiUrl + '/recursos';
  private apiPermissao: string = this.apiUrl + '/permissoes';
  private apiUsuarioGrupo: string = this.apiUrl + '/usuarioGrupo';


  // GRUPO
  public buscarGrupos() {
    return this.http.get<GrupoModel[]>(this.apiGrupo);
  }

  public cadastrarGrupo(grupo: GrupoModel) {
    return this.http.post<any>(this.apiGrupo, grupo);
  }

  public atualizarGrupo(grupo: GrupoModel) {
    return this.http.put<any>(this.apiGrupo, grupo);
  }

  public removerGrupo(id: number) {
    return this.http.delete<any>(sprintf(this.apiGrupo.concat('/%u'), id));
  }

  public buscarDetalheGrupo(id: number) {
    return this.http.get<GrupoModel>(sprintf(this.apiGrupo.concat('/%u'), id));
  }


  // USUÁRIO GRUPO
  public buscarUsuarioGrupo() {
    return this.http.get<UsuarioGrupoModel[]>(this.apiUsuarioGrupo);
  }

  public cadastrarUsuarioGrupo(usuarioGrupo: UsuarioGrupoModel) {
    return this.http.post<any>(this.apiUsuarioGrupo, usuarioGrupo);
  }

  public atualizarUsuarioGrupo(usuarioGrupo: UsuarioGrupoModel) {
    return this.http.put<any>(this.apiUsuarioGrupo, usuarioGrupo);
  }

  public removerUsuarioGrupo(id: number) {
    return this.http.delete<any>(sprintf(this.apiUsuarioGrupo.concat('/%u'), id));
  }

  public buscarDetalheUsuarioGrupo(id: number) {
    return this.http.get<UsuarioGrupoModel>(sprintf(this.apiUsuarioGrupo.concat('/%u'), id));
  }


  // USUÁRIO
  public buscarUsuario() {
    return this.http.get<UsuarioModel[]>(this.apiUsuario);
  }

  public cadastrarUsuario(usuario: UsuarioModel) {
    return this.http.post<any>(this.apiUsuario, usuario);
  }

  public atualizarSenhaUsuario(usuario: UsuarioModel) {
    return this.http.put<any>(this.apiUsuario.concat('/nova/senha'), usuario);
  }

  public atualizarUsuario(usuario: UsuarioModel) {
    return this.http.put<any>(this.apiUsuario, usuario);
  }

  public removerUsuario(id: number) {
    return this.http.delete<any>(sprintf(this.apiUsuario.concat('/%u'), id));
  }

  public buscarDetalheUsuario(id: number) {
    return this.http.get<UsuarioModel>(sprintf(this.apiUsuario.concat('/%s'), id));
  }

  // RECURSO
  public buscarRecurso() {
    return this.http.get<RecursoModel[]>(this.apiRecurso);
  }

  // PERMISSÃO
  public buscarPermissao() {
    return this.http.get<PermissaoModel[]>(this.apiPermissao);
  }

  public cadastrarPermissao(permissao: PermissaoModel) {
    return this.http.post<any>(this.apiPermissao, permissao);
  }

  public atualizarPermissao(permissao: PermissaoModel) {
    return this.http.put<any>(this.apiPermissao, permissao);
  }
  
  public removerPermissao(id: number) {
    return this.http.delete<any>(sprintf(this.apiPermissao.concat('/%u'), id));
  }

  public buscarDetalhePermissao(id: number) {
    return this.http.get<PermissaoModel>(sprintf(this.apiPermissao.concat('/%u'), id));
  }

}

