import { OnInit, NgZone, Injector, Directive, HostListener } from '@angular/core';
import { DecimalPipe, DatePipe } from '@angular/common';
import { Util } from './util';
import { Storage } from './storage';
import { GlobalService } from './services/global.service';
import { AuthenticationService } from './services/authentication.service';
import { UsuarioModel } from './models/usuario.model';

@Directive()
export class BaseComponent implements OnInit {
  public utilHeight: number = 0;
  public utilWidth: number = 0;
  public loading: boolean = false;
  public faImage: string = "";
  public image: string = "";
  public title: string = "";
  public util: Util;
  public storage: Storage;
  public globalService: GlobalService;
  public authService: AuthenticationService;
  public resource: string = "";
  public usuario?: UsuarioModel;
  decimalPipe: DecimalPipe;
  datePipe: DatePipe;
  private ngZone: NgZone;
  public ativaImportar = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.utilHeight = event.target.innerHeight - (60 + 82);
    this.utilWidth = event.target.innerWidth - 36;
  }

  constructor(injector: Injector) {

    this.util = injector.get(Util);
    this.ngZone = injector.get(NgZone);
    this.storage = injector.get(Storage);
    this.globalService = injector.get(GlobalService);
    this.authService = injector.get(AuthenticationService);
    this.decimalPipe = injector.get(DecimalPipe);
    this.datePipe = injector.get(DatePipe);
    window.onresize = (e: any) => {
      this.ngZone.run(() => {
        this.setHeight();
      });
    };

  }

  ngOnInit(): void {
    this.setHeight();
    this.usuario = this.authService.getLoggedUser();
    this.ativaImportar = this.authService.validatesUserResource("IMPORTAR_PLANILHA");

  }

  public setHeight() {
    this.utilHeight = this.util.utilHeight - 82;
    this.utilWidth = this.util.utilWidth;
  }

  public getAccess(resource: string) {
    return this.authService.validatesUserResource(resource);
  }

  public showLoading() {
    window.setTimeout(() => { this.loading = true; }, 30);
  }

  public closeLoading() {
    window.setTimeout(() => { this.loading = false; }, 30);
  }

}
