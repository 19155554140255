<dx-action-sheet fxHide fxShow.xs fxShow.sm #actionSheet [items]="acoes" [visible]="false" [showTitle]="false" [showCancelButton]="true">
</dx-action-sheet>
<dx-action-sheet fxHide fxShow.xs fxShow.sm #acaoExportar [items]="acoesExportar" [visible]="false" [showTitle]="false" [showCancelButton]="true">
</dx-action-sheet>
<div fxLayout="row" class="header">
  <div fxFlex="20" fxFlex.sm='50' fxFlex.xs='50'  fxLayoutAlign="start center" class="title-content">
      <fa-icon *ngIf="imagem" [icon]="[faImagem, imagem]"></fa-icon>
      <span [ngClass]="util.isMobile ? 'title-sm' : 'title'">{{titulo}}</span>
  </div>
  <div fxFlex="80" fxFlex.sm='50' fxFlex.xs='50' fxLayoutAlign="end center" fxLayoutGap="8px" class="items-content">
    <dx-text-box [spellcheck]="true" *ngIf="exibirFiltro" #text (onInput)="eventoFiltrar(text)" placeholder="Pesquisar..." [showClearButton]="true" [width]="300" class="icon-black">
      <dxi-button name="find" location="after" [options]="findIcon"></dxi-button>
    </dx-text-box>
    <ng-content fxFlex="100"></ng-content>
    <dx-button fxHide.xs fxHide.sm *ngIf="exibirNovo" icon="file" text="Novo" type="success" stylingMode="outlined" (onClick)="eventoNovo()"></dx-button>
    <dx-button fxHide.xs fxHide.sm *ngIf="exibirAtualizar" icon="refresh" text="Atualizar" type="default" stylingMode="outlined" (onClick)="eventoAtualizar()"></dx-button>
    <dx-drop-down-button fxHide.xs fxHide.sm *ngIf="tiposExportacao" text="Exportar" icon="export" [items]="tiposExportacao" (onItemClick)="eventoExportar($event)"></dx-drop-down-button>
    <dx-button fxHide fxShow.xs fxShow.sm icon="overflow" *ngIf="acoes.length" (onClick)="actionSheet.visible = true"></dx-button>
  </div>
</div>
<dx-load-panel shadingColor="rgba(128,128,128,.5)" [(visible)]="carregar" [showIndicator]="true" [showPane]="true" [shading]="true"
  [closeOnOutsideClick]="false" message="Aguarde, carregando...">
</dx-load-panel>

